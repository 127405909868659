<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="noticeList-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="() => selectDetail()"
      >新增公告</el-button
    >
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="advice_title"
        label="公告标题"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="advice_content"
        label="公告内容"
      >
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="scope.row.advice_content"
            placement="right"
          >
            <div style="white-space: nowrap;white-space: nowrap;text-overflow: ellipsis;overflow: hidden">
              {{ scope.row.advice_content }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="company_name"
        label="公告对象"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="send_name"
        label="发布人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="publish_time"
        label="发布时间"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="操作" width="170px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => selectDetail(scope.row)"
            >查看公告</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      title="公告"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="公告标题" prop="adviceTitle">
            <el-input
              class="dialog-input"
              v-model="ruleForm.adviceTitle"
              maxLength="100"
              placeholder="请输入公告标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="公告内容" prop="adviceContent">
            <el-input
              type="textarea"
              class="dialog-input"
              v-model="ruleForm.adviceContent"
              :autosize="{ minRows: 6, maxRows: 6 }"
              placeholder="请输入公告内容"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="30px">
            <el-checkbox v-model="ruleForm.toState">发送对象</el-checkbox>
            <el-select
              class="common-screen-input"
              v-model="ruleForm.toId"
              filterable
              placeholder="请选择发送对象"
            >
              <el-option
                v-for="item in rolesCompany"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="30px">
            <el-checkbox v-model="ruleForm.timingState">定时发送</el-checkbox>
            <el-time-picker
              class="common-screen-input"
              v-model="ruleForm.timingTime"
              value-format="HH:mm:ss"
              placeholder="任意时间点"
            >
            </el-time-picker>
          </el-form-item>
          <!-- <quill-editor
            class="editor"
            v-model="content"
            ref="myQuillEditor"
          ></quill-editor> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitForm('ruleForm');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn, Config } from "../../utils/index.js";
// import { quillEditor } from "vue-quill-editor";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
import { noticeList, noticeAdd, noticeDetail } from "../../service/common.js";
export default {
  components: {
    Breadcrumb,
    // quillEditor,
  },
  data() {
    return {
      tableColumn,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "公告", isLink: false },
        { title: "公告设置", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      //   content: '', // 富文本
      params: {
        pageNum: 1,
        pageSize: 10,
        type: 1,
      },
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
      centerDialogVisible: false, // 修改密码员工弹窗
      ruleForm: {
        toState: true,
        toId: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 })[0].id
      },
      rules: {
        adviceTitle: [{ required: true, message: "请填写公告标题", trigger: "change" }],
        adviceContent: [{ required: true, message: "请填写公告内容", trigger: "change" }],
      },
      tableRow: "",
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  methods: {
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        type: 1,
      };
      this.getTableData();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getTableData();
    },
    async getTableData() {
      // 获取表格数据
      this.loading = true;
      let params = { ...this.params };
      let resData = (await noticeList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    async selectDetail(row) {
      // 查看详情
      if (row) {
        let resData = (await noticeDetail({}, row.id)).data;
        this.ruleForm = {
          id: resData.id,
          adviceTitle: resData.adviceTitle || "",
          adviceContent: resData.adviceContent || "",
          timingState: resData.timingState == 1 ? true : false,
          toState: resData.toState == 1 ? true : false,
          timingTime: resData.timingTime || "",
          toId: resData.toId,
        };
      } else {
        if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
        this.ruleForm = {
          toState: true,
          toId: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 })[0].id
        };
      }
      this.centerDialogVisible = true;
    },
    submitForm(formName) {
      // 修改密码确认
      let ruleForm = { ...this.ruleForm };
      if (ruleForm.timingState) {
        ruleForm.timingState = 1;
      } else {
        ruleForm.timingState = 0;
      }
      if (ruleForm.toState) {
        ruleForm.toState = 1;
      } else {
        ruleForm.toState = 0;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await noticeAdd(ruleForm);
          this.centerDialogVisible = false;
          this.getTableData();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.noticeList-container {
  text-align: left;
}
/deep/.el-dialog {
  width: 600px !important;
  padding-right: 16px;
}
// .dialog-input {
//   width: 520px !important;
// }
</style>
